<template>
    <div class="app-container">
        <el-form :model="form" ref="form" :rules="rules">
            <el-form-item prop="loginPwd" label="新密码:">
                <el-input v-model="form.loginPwd" type="password" placeholder="请输入新密码"/>
            </el-form-item>
            <el-form-item prop="correctPwd" label="确认密码:">
                <el-input v-model="form.correctPwd" type="password" placeholder="请输入确认密码"/>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submit">确认修改</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import {
        alterPasswordApi
    } from '@/api'
    export default {
        name: 'user',
        data() {
            const validateCorrect = (rule, value, callback) => {
                if (value === '') {
                callback(new Error('请再次输入密码'))
                } else if (value !== this.form.loginPwd) {
                callback(new Error('两次输入密码不一致!'))
                } else {
                callback()
                }
            }
            return {
                form: {
                    id: '',
                    loginPwd: '',
                    correctPwd: ''
                },
                rules: {
                    loginPwd: [
                        { required: true, message: '请输入密码', trigger: 'blur' }
                    ],
                    correctPwd: [
                     { validator: validateCorrect, trigger: 'blur' }
                    ]
                }
            }
        },
        mounted() {
            const userInfo = JSON.parse(localStorage.getItem('userInfo'))
            this.form.id = userInfo.id
        },
        methods: {
           submit() {
               this.$refs['form'].validate(valid => {
                   if (valid) {
                       alterPasswordApi({
                           id: this.form.id,
                           loginPwd: this.form.loginPwd
                       }).then(res => {
                           if (res) {
                             this.$message.success('修改成功')
                             this.$router.go(-1)
                           }
                       })
                   }
               })
           }
        }
    }
</script>

<style scoped>

</style>
